// src/components/LoadingScreen.js
import React from 'react';
import {
  BrowserButton,
  BrowserFrame,
  BrowserHeader,
  Text,
  ContentWrapper,
  BodyWrapper
} from '../styles/LoadingBarStyles';

import flowerImage from '../assets/images/flor.png';
import pencilImage from '../assets/images/lapis.png';

import {
  Flower,
  InstagramText,
  Pencil
} from '../styles/LoadingScreenStyles';
import LoadingBar from './LoadingBar';


const LoadingScreen = () => {

  return (
    <BodyWrapper>
      <Pencil src={pencilImage} alt="Pencil" />
      <Flower src={flowerImage} alt="Flower" />
      <ContentWrapper>
        <InstagramText>
          <a href="https://www.instagram.com/escola_organizada" target="_blank" rel="noopener noreferrer">
            @escola_organizada
          </a>
        </InstagramText>
        <BrowserFrame>
          <BrowserHeader>
            <BrowserButton color={({ theme }) => theme.colors.background} />
            <BrowserButton color={({ theme }) => theme.colors.white} />
            <BrowserButton color={({ theme }) => theme.colors.white} />
          </BrowserHeader>
          <LoadingBar />
          <Text>Carregando um site incrível para nossa comunidade...</Text>
        </BrowserFrame>
      </ContentWrapper>
    </BodyWrapper>
  );
};

export default LoadingScreen;
