export const theme = {
  colors: {
    primary: '#006965',
    secondary: '#ff5f56',
    accent: '#ffbd2e',
    background: '#ffd4e5',
    text: '#333',
    border: '#004d40',
    browserHeader: '#e0e0e0',
    buttonBorder: '#000000',
    white: '#FFFFFF',
  },
  fonts: {
    main: 'Poppins, sans-serif',
    secondary: 'Poppins, sans-serif',
    instagram: 'More Sugar',
  },

};
