import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: 'Arial', sans-serif;

  @media (max-width: 600px) {
    padding: 20px;
  }
`;
export const Flower = styled.img`
  position: absolute;
  top: 0%;
  right: 0%;
  width: 500px;
  height: 380px;
  z-index:1;
  ${'' /* width: 40%;
  height: 50%; */}

  @media (max-width: 600px) {
    width: 75%;
    height: 40%;
  }
`;

export const Pencil = styled.img`
  position: absolute;
  left: 0%;
  bottom: 10%;
  ${'' /* width: 10%;
  height: 60%; */}
  width: 80px;
  height: 500px;
  z-index:1;


  @media (max-width: 600px) {
    width: 15%;
    height: 50%;
    bottom: 5%;
  }
`;

export const LoadingBar = styled.div`
  width: 200px;
  height: 30px;
  border: 2px solid #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  & > div {
    width: 180px;
    height: 20px;
    background-color: #004d40;
  }

  @media (max-width: 600px) {
    width: 150px;
    height: 20px;

    & > div {
      width: 140px;
      height: 16px;
    }
  }
`;

export const Text = styled.p`
  font-size: 16px;
  color: #333;
  margin-top: 20px;
  font-family: ${({ theme }) => theme.fonts.main};

  @media (max-width: 600px) {
    font-size: 14px;
    text-align: center;
  }
`;

export const InstagramText = styled.p`
  font-size: 55px;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fonts.instagram};

  @media (max-width: 600px) {
    font-size: 40px;
  }
    a {
    text-decoration: none;
    color: inherit;

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: inherit;
    }
  }
`;

export const InstagramHandle = styled.p`
  font-size: 16px;
  color: #333;
  margin-top: 10px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
