import styled from 'styled-components';

export const N_BARS = 15;

export const BodyWrapper = styled.div`
  display: flex;
  flex:1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  font-family: ${({ theme }) => theme.fonts.main};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: ${({ theme }) => theme.fonts.main};
  z-index:1;

`;

export const BrowserFrame = styled.div`
  width: 400px;
  height: 200px;
  background-color: #f0eae0;
  border: 2px solid ${({ theme }) => theme.colors.border};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  
  @media (max-width: 600px) {
    width: 65%;
  }

`;

export const BrowserHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.browserHeader};
  border-bottom: 2px solid ${({ theme }) => theme.colors.border};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  top: 0;
`;

export const BrowserButton = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color || '#ccc'};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  margin-right: 5px;
`;

export const LoadingBarContainer = styled.div`
  width: 80%;
  height: 60px;
  border: 2px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  overflow: hidden;
  padding: 4px 1px 4px 4px;
`;

export const Block = styled.div`
  width: 10%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-right: 4px;
  visibility: hidden;
`;

export const Text = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};
  margin: 20px 10px 10px 10px;
  text-align: center;
`;
