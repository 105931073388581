// src/components/LoadingScreen.js
import React, { useState, useEffect } from "react";
import { LoadingBarContainer, Block, N_BARS } from "../styles/LoadingBarStyles";

const LoadingBar = () => {
  const [visibleBlocks, setVisibleBlocks] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleBlocks((prev) => {
        if (prev >= N_BARS + 5) {
          return 0;
        }
        return prev + 1;
      });
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <LoadingBarContainer>
      {[...Array(N_BARS)].map((_, index) => (
        <Block
          key={index}
          style={{ visibility: index < visibleBlocks ? "visible" : "hidden" }}
        />
      ))}
    </LoadingBarContainer>
  );
};

export default LoadingBar;
